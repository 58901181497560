:global {
  .background-video {
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
  .sell-section-gradient {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(0, 48, 73);
    background: linear-gradient(90deg, rgba(0, 48, 73, 0.7) 0%, rgba(0, 48, 73, 0.24) 100%);
  }
  .category-gradient {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0.4) 60%,
      rgba(0, 0, 0, 0.1) 100%
    );
  }

  .live-auctions-bg-gradient {
    background: rgb(49, 83, 101);
    background: radial-gradient(
      circle,
      rgba(49, 83, 101, 1) 0%,
      rgba(66, 71, 74, 1) 100%,
      rgba(112, 112, 112, 1) 100%
    );
  }

  :local(.home-page) {
    text-align: left;
    .header-section {
      background: url('/images/home-header.png') #012138 center top;
      text-align: center;
      height: 80vh;
      position: relative;

      &.with-titles {
        padding-bottom: 146px;
      }

      .cover {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgb(1, 33, 56);
        background: linear-gradient(
          266deg,
          rgb(1, 33, 56) -20%,
          rgba(33, 61, 81, 0.6) 100%,
          rgba(85, 106, 122, 0) 100%
        );
      }

      .content {
        position: relative;
        width: 80%;
        margin: auto;
      }

      .background-slice {
        position: absolute;
        bottom: 36px;
        left: 0;
        right: 0;

        &:after {
          content: '';
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          padding-bottom: 36px;
          background: #012138;
          clip-path: polygon(0% 0%, 50% 100%, 100% 0%, 100% 100%, 0 100%);
        }
      }
    }
    .header-section-with-auth {
      background: url('/images/home-page-bg.png') #012138 center top;
      position: relative;
      text-align: center;

      &.with-titles {
        padding-bottom: 146px;
      }

      .background-slice {
        position: absolute;
        bottom: 36px;
        left: 0;
        right: 0;

        &:after {
          content: '';
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          padding-bottom: 36px;
          background: #012138;
          clip-path: polygon(0% 0%, 50% 100%, 100% 0%, 100% 100%, 0 100%);
        }
      }
    }

    .page-logo {
      padding: 54px 0 45px;

      img {
        height: 134px;
      }
    }

    .text-highlight {
      color: #cbb133;
    }

    .page-titles {
      padding: 0 20px;
      margin-bottom: 45px;
    }

    .page-title,
    .page-subtitle {
      color: white;
      margin: 0 auto;
    }

    .page-title {
      font-size: 24px;
      line-height: 27px;
      margin-bottom: 16px;
      max-width: 950px;
    }

    .page-subtitle {
      font-size: 16px;
      line-height: 22px;
      max-width: 740px;
    }

    .categories-section {
      padding-top: 78px;
      background: #012138;
      padding-bottom: 272px;
      margin-bottom: -272px;
      text-align: center;
    }
    .section-title {
      font-weight: bold;
      font-size: 30px;
      line-height: 36px;
      text-align: center;
      color: #ffffff;
    }

    .categories-title {
      margin-bottom: 38px;
    }

    .category-link {
      min-width: 143px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 8px;
      transition: 0.3s;
      padding: 0 20px;
    }

    .category-link:not(:hover) {
      border: 2px solid rgba(191, 199, 205, 0.5);
      background: none;
    }

    .category-link:hover {
      border: 2px solid rgba(191, 199, 205, 0.5);
      background: #cbb135;
    }

    .just-listed-section {
      padding: 100px 0 130px;
    }

    .recent-deals-section,
    .live-auctions {
      padding-bottom: 144px;

      .section-title {
        color: #012138;
      }
    }

    .showcase-items-section {
      .section-title {
        margin-bottom: 46px;
      }

      .list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        height: 290px;
        overflow: hidden;
        .mobile & {
          overflow: unset;
          height: auto;
        }
      }

      .item {
        margin: 0 15px;
      }
    }

    .live-stream-items-section {
      .section-title {
        margin-bottom: 46px;
      }

      .list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        height: 290px;
        overflow: hidden;
        .mobile & {
          overflow: unset;
          height: auto;
        }
      }

      .item {
        margin: 0 15px;
      }
    }
  }
}
